export default [
	/* {
		name: "home",
		icon: "accessibility",
		href: "/",
		public: true,
		visibleInMenu: true,
		withFooter: true,
	}, */

	// {
	// 	name: "dashboard",
	// 	roles: ["root"],
	// 	icon: "accessibility",
	// 	href: "/dashboard",
	// 	visibleInMenu: true,
	// 	public: false,
	// },
	// {
	// 	name: "post",
	// 	roles: ["root"],
	// 	icon: "article",
	// 	href: "/post",
	// 	public: false,
	// },
	{
		name: 'demo',
		roles: ['root'],
		icon: 'home',
		href: '/demo',
		visibleInMenu: false,
		public: false,
		dislayedName: 'demo'
	},
	{
		name: 'post',
		roles: ['root'],
		icon: 'article',
		href: '/post',
		visibleInMenu: false,
		public: false
	},

	{
		name: 'auth',
		public: true,
		icon: 'account_circle',
		href: '/auth',
		visibleInMenu: false
	},
	{
		name: 'file',
		public: false,
		icon: 'attach_file',
		href: '/file',
		roles: ['root'],
		visibleInMenu: true,
		dislayedName: 'Fájlok'
	},
	{
		name: 'images',
		public: false,
		icon: 'attach_file',
		href: '/images',
		roles: ['root'],
		visibleInMenu: true,
		dislayedName: 'Képek'
	},
	{
		name: 'news',
		public: true,
		icon: 'announcement',
		href: '/news/:id',
		visibleInMenu: false
	},
	{
		name: 'editnews',
		public: false,
		icon: 'announcement',
		href: '/editnews',
		roles: ['root'],
		visibleInMenu: true,
		dislayedName: 'Hírek szerkesztése'
	},
	{
		name: 'editHotel',
		public: false,
		icon: 'crop_original',
		href: '/editHotel',
		roles: ['root'],
		visibleInMenu: true,
		dislayedName: 'Szálláshelyek szerkesztése'
	},
	{
		name: 'hotel',
		public: true,
		icon: 'crop_original',
		href: '/hotel',
		visibleInMenu: false
	},
	{
		name: 'madefalvahome',
		public: true,
		icon: 'home',
		href: '/',
		roles: [],
		visibleInMenu: false
	},
	{
		name: 'officehome',
		public: true,
		icon: 'home',
		href: '/',
		roles: [],
		visibleInMenu: false
	},
	{
		name: 'monumenthistory',
		public: true,
		roles: [],
		visibleInMenu: false,
		href: '/history/monument'
	},
	{
		name: 'editoffice',
		public: false,
		icon: 'attach_file',
		href: '/editoffice',
		roles: ['root'],
		visibleInMenu: true,
		dislayedName: 'Hivatal szerkesztése'
	},
	{
		name: 'editcouncil',
		public: false,
		icon: 'crop_original',
		href: '/editcouncil',
		roles: ['root'],
		visibleInMenu: true,
		dislayedName: 'Tanács és díszpolgárok szerkesztése'
	},
	{
		name: 'news',
		public: true,
		icon: 'announcement',
		href: '/news/:id',
		visibleInMenu: false
	},
	{
		name: 'lives',
		public: true,
		href: '/lives/:id',
		visibleInMenu: false
	},
	{
		name: 'hotel',
		public: true,
		icon: 'announcement',
		href: '/hotel/:id',
		visibleInMenu: false
	},
	{
		name: 'sights',
		public: true,
		icon: 'announcement',
		href: '/sights',
		visibleInMenu: false
	},
	{
		name: 'sight',
		public: true,
		icon: 'announcement',
		href: '/sight/:id',
		visibleInMenu: false
	},
	{
		name: 'editsight',
		public: false,
		icon: 'crop_original',
		href: '/editsight',
		roles: ['root'],
		visibleInMenu: true,
		dislayedName: 'Látnivalók szerkesztése'
	},
	{
		name: 'editcommunity',
		public: false,
		icon: 'crop_original',
		href: '/editcommunity',
		roles: ['root'],
		visibleInMenu: true,
		dislayedName: 'Közösségteremtők szerkesztése'
	},
	{
		name: 'edit/amade-tv',
		public: false,
		href: '/edit/amade-tv',
		roles: ['root'],
		visibleInMenu: true,
		icon: 'crop_original',
		dislayedName: 'AmadéTV szerkesztése'
	},
	{
		name: 'edit/alpatvar',
		public: false,
		href: '/edit/alpatvar',
		roles: ['root'],
		visibleInMenu: true,
		icon: 'crop_original',
		dislayedName: 'Alpatvar szerkesztése'
	},
	{
		name: 'hotels',
		public: true,
		icon: 'crop_original',
		href: '/hotels',
		visibleInMenu: false
	},
	{
		name: 'community',
		public: true,
		icon: 'crop_original',
		href: '/community',
		visibleInMenu: false
	},
	{
		name: 'council',
		public: true,
		icon: 'crop_original',
		href: '/council',
		visibleInMenu: false
	},
	{
		name: 'publicInterest',
		public: true,
		icon: 'crop_original',
		href: '/pub_int',
		visibleInMenu: false
	},
	{
		name: 'localCouncil',
		public: true,
		icon: 'crop_original',
		href: '/localcouncil',
		visibleInMenu: false
	},

	{
		name: 'organigram',
		public: true,
		icon: 'crop_original',
		href: '/organigram',
		visibleInMenu: false
	},
	{
		name: 'decl_avr',
		public: true,
		icon: 'crop_original',
		href: '/decl_avr',
		visibleInMenu: false
	},
	{
		name: 'isaticf',
		public: true,
		icon: 'crop_original',
		href: '/isaticf',
		visibleInMenu: false
	},
	{
		name: 'anticorruption_startegy',
		public: true,
		icon: 'crop_original',
		href: '/anticorruption_startegy',
		visibleInMenu: false
	},
	{
		name: 'gazette',
		public: true,
		icon: 'crop_original',
		href: '/gazette',
		visibleInMenu: false
	},
	{
		name: 'socialoffice',
		public: true,
		icon: 'crop_original',
		href: '/soc_off',
		visibleInMenu: false
	},
	{
		name: 'registryoffice',
		public: true,
		icon: 'crop_original',
		href: '/registryoffice',
		visibleInMenu: false
	},
	{
		name: 'monumenthistory',
		public: true,
		roles: [],
		visibleInMenu: false,
		href: '/history/monument'
	},
	{
		name: 'madefalvahistory',
		public: true,
		roles: [],
		visibleInMenu: false,
		href: '/history/madefalva'
	},
	{
		name: 'siculicidiumhistory',
		public: true,
		roles: [],
		visibleInMenu: false,
		href: '/history/siculicidium'
	},
	{
		name: 'siculicidiumhistory3',
		public: true,
		roles: [],
		visibleInMenu: false,
		href: '/history/siculicidium3'
	},
	{
		name: 'siculicidiumhistory4',
		public: true,
		roles: [],
		visibleInMenu: false,
		href: '/history/siculicidium4'
	},
	{
		name: 'siculicidiumhistory5',
		public: true,
		roles: [],
		visibleInMenu: false,
		href: '/history/siculicidium5'
	},
	{
		name: 'siculicidiumhistory6',
		public: true,
		roles: [],
		visibleInMenu: false,
		href: '/history/siculicidium6'
	},
	{
		name: 'siculicidiumhistory7',
		public: true,
		roles: [],
		visibleInMenu: false,
		href: '/history/siculicidium7'
	},
	{
		name: 'siculicidiumhistory8',
		public: true,
		roles: [],
		visibleInMenu: false,
		href: '/history/siculicidium8'
	},
	{
		name: 'siculicidiumhistory9',
		public: true,
		roles: [],
		visibleInMenu: false,
		href: '/history/siculicidium9'
	},
	{
		name: 'siculicidiumhistory10',
		public: true,
		roles: [],
		visibleInMenu: false,
		href: '/history/siculicidium10'
	},
	{
		name: 'siculicidiumhistory11',
		public: true,
		roles: [],
		visibleInMenu: false,
		href: '/history/siculicidium11'
	},
	{
		name: 'siculicidiumhistory12',
		public: true,
		roles: [],
		visibleInMenu: false,
		href: '/history/siculicidium12'
	},
	{
		name: 'editofficialgazette',
		public: false,
		icon: 'crop_original',
		href: '/editofficialgazette',
		roles: ['root'],
		visibleInMenu: true,
		dislayedName: 'Közlöny szerkesztése'
	},
	{
		name: 'editprocurements',
		public: false,
		icon: 'crop_original',
		href: '/editprocurements',
		roles: ['root'],
		visibleInMenu: true,
		dislayedName: 'Közbeszerzések szerkesztése'
	},
	{
		name: 'language',
		public: true,
		href: '/language',
		roles: [],
		visibleInMenu: false
	},
	{
		name: 'amade-tv',
		public: true,
		href: '/amade-tv',
		roles: [],
		visibleInMenu: false
	},
	{
		name: 'alpatvar',
		public: true,
		href: '/alpatvar',
		roles: [],
		visibleInMenu: false
	},
	{
		name: 'procurements',
		public: true,
		href: 'procurements',
		roles: [],
		visibleInMenu: false
	},
	{
		name: 'procurement',
		public: true,
		href: '/procurement/:id',
		visibleInMenu: false
	}
];
