import {
	Box,
	IconButton,
	ListItem,
	makeStyles,
	Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { updateElement } from '../../../../../store/common/actions';
import EditModal from '../../../EditModal/EditModal';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '8px',
	},
	title: {},
	titleInactive: {
		color: 'gray',
	},
	buttons: {
		marginLeft: 'auto',
	},
}));

export default (props) => {
	const { item } = props;
	const classes = useStyles();
	const [editOpen, setEditOpen] = useState(false);

	const updateItem = (data) => {
		updateElement(item.id, data);
	};
	return (
		<ListItem divider className={classes.root}>
			<Typography
				className={item.active ? classes.title : classes.titleInactive}>
				Editable
			</Typography>
			<Box className={classes.buttons}>
				<IconButton onClick={() => setEditOpen(true)}>
					<EditIcon />
				</IconButton>
				<EditModal
					open={editOpen}
					setOpen={setEditOpen}
					onConfirm={updateItem}
					item={item}
				/>
			</Box>
		</ListItem>
	);
};
