import { useEffect, useRef, useState } from 'react';

export const useIntersect = (options, onIntersect, onNonIntersect = null) => {
	const [node, setNode] = useState(null);

	const observer = useRef(null);

	useEffect(() => {
		if (observer.current) {
			observer.current.disconnect();
		}
		observer.current = new IntersectionObserver(([v]) => {
			if (onNonIntersect && !v.isIntersecting) {
				onNonIntersect();
			}
			if (onIntersect && v.isIntersecting) {
				onIntersect();
			}
		}, options);
		const { current: currentObserver } = observer;
		if (node) {
			currentObserver.observe(node);
		}

		return () => {
			currentObserver.disconnect();
		};
	}, [node, options, onIntersect, onNonIntersect]);

	return [setNode];
};
