import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Formik, Form, Field, FieldArray } from 'formik';
import { withNamespaces } from 'react-i18next';
import { RichTextEditor } from '../../../components/form';
import { COMMON_CATEGORIES } from '../../../config/common-categories';

const useStyles = makeStyles(() => ({
	root: {
		maxWidth: '70vw',
	},
	fieldArrayItem: {
		border: '1px solid gray',
		margin: '30px',
		padding: '30px',
	},
}));

export default withNamespaces(['common', 'sight'])((props) => {
	const { item, open, setOpen, onConfirm, t } = props;

	const classes = useStyles();
	return (
		<Formik
			enableReinitialize
			initialValues={{
				content: item
					? item.content
					: [
						{
							lang: 'hu',
						},
						{
							lang: 'ro',
						},
					  ],
				category: COMMON_CATEGORIES.ALPATVAR,
			}}
			onSubmit={(values) => {
				onConfirm(values);
				setOpen(false);
			}}>
			{({
				values,
				errors,
				touched,
				handleReset,
				setFieldValue,
				submitForm,
			}) => (
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					classes={{ paper: classes.root }}
					aria-labelledby='edit-dialog'>
					<DialogTitle id='confirm-dialog'>Edit</DialogTitle>
					<DialogContent>
						<Form>
							<FieldArray
								name='content'
								render={() => (
									<Box>
										{values.content.map((item, index) => (
											<Box key={index} className={classes.fieldArrayItem}>
												<Typography variant='h2'>
													{t(`common:${item.lang}`)}
												</Typography>
												<Field
													name={`content[${index}].description`}
													component={RichTextEditor}
													size='small'
													label='Text'
													defaultValue={item.description}
												/>
											</Box>
										))}
									</Box>
								)}
							/>
						</Form>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							onClick={() => setOpen(false)}
							color='default'>
							{t('cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							color='secondary'
							onClick={submitForm}>
							{t('save')}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Formik>
	);
});
