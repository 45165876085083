import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Formik, Form, Field, FieldArray } from 'formik';
import { withNamespaces } from 'react-i18next';
import { RichTextEditor } from '../../../components/form';

const useStyles = makeStyles(() => ({
	root: {
		maxWidth: '70vw',
	},
	fieldArrayItem: {
		border: '1px solid gray',
		margin: '30px',
		padding: '30px',
	},
}));

export default withNamespaces(['common', 'sight'])((props) => {
	const { open, setOpen, onConfirm, t } = props;

	const classes = useStyles();
	return (
		<Formik
			initialValues={{
				category: 'alpatvar',
				content: [
					{
						lang: 'hu',
					},
					{
						lang: 'ro',
					},
				],
			}}
			onSubmit={(values, { resetForm }) => {
				onConfirm(values).then((result) => {
					if (result) {
						resetForm({});
						setOpen(false);
					}
				});
			}}>
			{({ values, submitForm }) => (
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					classes={{ paper: classes.root }}
					aria-labelledby='edit-dialog'>
					<DialogTitle id='confirm-dialog'>{t('newelementOffice')}</DialogTitle>
					<DialogContent>
						<Form>
							<FieldArray
								name='content'
								render={() => (
									<Box>
										{values.content.map((item, index) => (
											<Box key={index} className={classes.fieldArrayItem}>
												<Typography variant='h2'>
													{t(`common:${item.lang}`)}
												</Typography>
												<Field
													name={`content[${index}].description`}
													component={RichTextEditor}
													size='small'
													label='Text'
												/>
											</Box>
										))}
									</Box>
								)}
							/>
						</Form>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							onClick={() => setOpen(false)}
							color='secondary'>
							{t('cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							color='default'
							onClick={submitForm}>
							{t('save')}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Formik>
	);
});
