import React, { useEffect } from 'react';
import {
	makeStyles,
	Box,
	Typography,
	withWidth,
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';

import { useSelector } from 'react-redux';
import moment from 'moment';
import { Village2 } from '../../../components/icons';
import { getByCategory } from '../../../store/common/actions';
import { COMMON_CATEGORIES } from '../../../config/common-categories';
import { PostBody } from '../../../components/layout';
import { getAllOfficialGazette } from '../../../store/officialGazette/actions';
import { FileCard } from '../Council/components/FileCard';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		width: '100%',
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	contentContainer: {
		boxSizing: 'border-box',
		maxWidth: 1128,
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#FAFAF5',
		width: '100%',
		padding: theme.spacing(2, 9),
		marginTop: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			padding: 20,
		},
	},
	titleContainer: {
		width: '100%',
		height: 25,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 8,
		borderBottom: '1px solid #D5D4C9',
	},
	village2Container: {
		paddingTop: '40px',
	},
	contentText: {
		lineHeight: '32px',
		fontSize: '14px',
		marginTop: '40px',
		fontWeight: 600,
		color: '#070c35',
		height: 'fit-content',
	},
	titledate: {
		color: '#E0B080',
		fontFamily: 'Ubuntu',
		fontWeight: 'medium',
		fontSize: '16px',
		fontHeight: '24px',
		marginBottom: theme.spacing(3),
	},
	contentContainer2: {
		maxWidth: 1164,
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingTop: 30,
		marginTop: '40px'
	},
	content: {
		display: 'flex',
		flexDirection: 'column'
	},
}));

export default withNamespaces('council')(
	withWidth()((props) => {
		const { t } = props;
		const classes = useStyles();
		const files = useSelector((state) => state.gazette.list);
		const data = useSelector((state) => state.common);
		const language = useSelector((state) => state.language.language);

		useEffect(() => {
			getByCategory(COMMON_CATEGORIES.ALPATVAR);
		}, []);

		useEffect(() => {
			getAllOfficialGazette('alpatvar');
		}, []);

		const langContent = data[0]
			? data[0].content.find((item) => item.lang === language)
			: null;

		return (
			<Box className={classes.pageContainer}>
				<Box className={classes.contentContainer}>
					<Typography className={classes.contentText}>
						<Typography className={classes.titledate}>
							{data &&
								data[0] &&
								data[0].lastChangedAt &&
								moment(data[0].lastChangedAt).format('MMMM DD, YYYY')}
						</Typography>
						{langContent && <PostBody content={langContent.description} />}
					</Typography>
				</Box>
				<Box className={classes.contentContainer2}>
					<Box className={classes.content}>
						{!!files?.items?.length &&
							files.items.map((item) => <FileCard key={item.id} file={item} />)}
					</Box>
				</Box>
				<Box className={classes.village2Container}>
					<Village2 />
				</Box>
			</Box>
		);
	}),
);
