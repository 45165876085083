import store from '../store';
import { GET_ALL_NEWS } from '../types';
import { api } from '../../lib/axios/api';
import { openSnackbar } from '../snackbar/actions';
import { transformQuery } from '../../lib/helpers/queryTransformer';

export const getAllNews = async (params) => {
	const query = { ...params, page: 1, limit: 20 };
	await api.get(`news${transformQuery(query)}`).then((response) => {
		if (response.data) {
			const news = response.data;
			news.items = news.items.filter((item) => !!item.content.length);
			news.params = params;
			store.dispatch({
				type: GET_ALL_NEWS,
				payload: news,
			});
		}
	});
};

export const getAllNewsNextPage = async () => {
	const current = store.getState().news.list;
	if (current.meta?.currentPage < current.meta?.totalPages) {
		const query = {
			...current.params,
			page: current.meta.currentPage + 1,
			limit: 20,
		};
		const data = await api.get(`news${transformQuery(query)}`);
		if (data.data) {
			const res = data.data;
			const newItems = data.data.items.filter((item) => !!item.content.length);
			res.items = [...current.items, ...newItems];
			res.params = current.params;
			store.dispatch({
				type: GET_ALL_NEWS,
				payload: res,
			});
		}
	}
};

export const getElements = (other) => {
	api.get(other ? `/news${other}` : '/news?active=true').then((response) => {
		store.dispatch({ type: GET_ALL_NEWS, payload: response.data });
	});
};

export const saveElement = async (data) =>
	api.post('/news', data).then(() => {
		openSnackbar({ message: 'Sikeres mentes!', severity: 'success' });
		getElements('?active=false');
		return true;
	});

export const updateElement = (id, data) => {
	api
		.put(`/news/${id}`, data)
		.then(() => getElements('?active=false'))
		.catch((e) => console.log(e));
};

export const deleteElement = (id) => {
	api.delete(`/news/${id}`).then(() => getElements('?active=false'));
};

export const activateElement = (id, status) => {
	api
		.patch(`/news/${id}?active=${!!status}`)
		.then(() => getElements('?active=false'));
};
