import React from 'react';
import { List } from './List';
import { FileList } from './FileList';

export default ({ t }) => {
	return (
		<>
			<div>
				<List />
				<FileList />
			</div>
		</>
	);
}
