import React, { useCallback, useMemo } from 'react';
import { Box, makeStyles, Grid, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Header } from './components/Header';
import 'moment/min/locales';
import { NewsCard } from './components/NewsCard';
import { useIntersect } from '../../../hooks/useIntersect';
import { getAllNewsNextPage } from '../../../store/news/actions';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		maxWidth: '1128px',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	mainContainer: {
		// display: "flex",
		// flexWrap: "wrap",
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			// justifyContent: "",
		},
	},
	loaderContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	loader: {
		mx: 'auto',
		marginTop: '24px',
		marginBottom: '32px',
	},
}));

function News() {
	const data = useSelector((state) => state.news.list);
	const classes = useStyles();

	const hasMore = useMemo(() => {
		if (!data) {
			return false;
		}
		return data.meta?.currentPage < data.meta?.totalPages;
	}, [data]);

	const [setBottomNode] = useIntersect({ threshold: 0 }, () => {
		if (hasMore) {
			getAllNewsNextPage();
		}
	});

	const bottomRef = useCallback(
		(node) => {
			if (node) {
				setBottomNode(node);
			}
		},
		[setBottomNode],
	);

	return (
		<Box className={classes.pageContainer}>
			<Header />
			{data?.items && (
				<Box className={classes.mainContainer}>
					<Grid container>
						{data.items.map((item) => (
							<Grid key={item.id} item xs={12} xl={3} md={4} sm={6} lg={3}>
								<NewsCard newsItem={item} />
							</Grid>
						))}
					</Grid>
					<div
						ref={bottomRef}
						className={classes.loaderContainer}
						style={{ visibility: hasMore ? 'visible' : 'hidden' }}>
						<CircularProgress
							color='primary'
							variant='circleIndeterminate'
							size={30}
							className={classes.loader}
						/>
					</div>
				</Box>
			)}
		</Box>
	);
}
export default News;
